export enum ExternalReviewSourceDTO {
    Google = 'GOOGLE',
    TripAdvisor = 'TRIP_ADVISOR',
}

export class CommunicationPreferencesDTO {

    constructor(
        public sendReminderHours: number | null,
        public sendFeedbackRequest: boolean,
        public sendFeedbackRequestMinutes: number,
        public externalReviewSource: ExternalReviewSourceDTO | null,
        public externalReviewUrl: string | null,
        public externalReviewCount: number | null,
        public externalReviewDescription: string | null,
        public emailAddress: string | null,
        public replyToEmailAddress: string | null,
        public feedbackEmailRecipients: string[] | null,
        public sendRequestEmail: boolean,
        public sendConfirmationEmail: boolean,
        public sendCancellationEmail: boolean,
        public sendModificationEmail: boolean,
        public sendNoShowEmail: boolean,

        public sendEmailPerReviewCount: boolean,
        public sendFeedbackDailySummaryEmail: boolean,

        public sendUpcomingEmail: boolean,
        public sendNotificationWithNoteOnly: boolean,
        public sendNotificationMinimumPartySizeOnly: number | null,
        public diningInformation: string | null
    ) { }
}

