import { Order } from '@app/domain/Order'
import { DeliveryDetails } from '@app/domain/DeliveryDetails'
import { Payment } from '@app/domain/Payment'

export class Sale {
    constructor(
        public id: string,
        public venueId: string,
        public order: Order,
        public payment: Payment | null,
        public deliveryDetails: DeliveryDetails | null,
        public dateCreated: Date,
        public dateUpdated: Date
    ) { }
}
