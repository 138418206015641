export class LineItem {
    constructor(
        public id: string,
        public productId: string,
        public skuId: string,
        public name: string,
        public amount: number,
        public quantity: number,
        public dateCreated: Date,
        public dateUpdated: Date
    ) { }
}
