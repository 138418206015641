import { LineItem } from '@app/domain/LineItem'

export class Order {
    constructor(
        public id: string,
        public venueId: string,
        public lineItems: LineItem[],
        public currencyCode: string,
        public dateCreated: Date,
        public dateUpdated: Date
    ) { }
}
