import shortUUID from 'short-uuid'

export class GiftedVoucher {

    constructor(
        public id: string,
        public venueId: string,
        public lineItemId: string,
        public amount: number,
        public currencyCode: string,
        public redeemed: boolean,
        public expiryDate: Date | null,
        public dateCreated: Date,
        public dateUpdated: Date
    ) { }

    get code(): string {
        const translator = shortUUID()
        const base58Id = translator.fromUUID(this.id)
        return base58Id.toUpperCase()
    }
}
