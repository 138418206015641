import { DateComponent } from '@app/domain/DateComponent'
import { PartySizeDuration } from '@app/domain/PartySizeDuration'

export class EventDTO {
    constructor(
        public id: string,
        public displayName: string,
        public displayOrder: number,
        public description: string,
        public bookingDurations: PartySizeDuration[],
        public imageId: string | null,
        public cancellationChargeAmount: number | null,
        public cancellationChargePartyAmount: number | null,
        public cancellationChargeCutOffHours: number | null,
        public depositAmount: number | null,
        public depositTableCapacityAmount: number | null,
        public depositRefundCutOffDays: number | null,
        public diningInformation: string | null,
        public isExclusive: boolean,
        public notReservableOnline: boolean,
        public notReservableOnlineMessage: string | null,
        public forceSendMerchantEmail: boolean,
        public contributesToKitchenVelocity: boolean,
        public preBookingWindowMinutes: number | null,
        public preBookingModificationWindowMinutes: number | null,
        public recurrence: EventRecurrenceDTO,
        public tableIds: string[][],
        public questionIds: string[],
        public partySizeRestriction: number[] | null,
        public dailyBookingLimit: number | null,
        public partySizeRestrictedMessage: string | null
    ) { }
}

export class EventRecurrenceDTO {
    constructor(
        public startDates: string[],
        public dateComponent: DateComponent | null,
        public weeklyRepeatsOnDaysOfWeek: number[] | null,
        public monthlyRepetitionType: MonthlyRepetitionTypeDTO | null,
        public exceptions: EventExceptionDTO[],
        public endDate: string | null,
        public startTime: string | null,
        public endTime: string | null
    ) { }
}

export enum MonthlyRepetitionTypeDTO {
    DAY_OF_MONTH = 'DAY_OF_MONTH',
    DAY_OF_WEEK = 'DAY_OF_WEEK',
}

export class EventExceptionDTO {
    constructor(
        public id: string,
        public date: string
    ) { }
}
