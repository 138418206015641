import { Product } from './Product'
import { SKU } from './SKU'

export class Voucher extends Product {
    constructor(
        id: string,
        displayName: string,
        description: string | null,
        public policy: string | null,
        public expiryMonths: number | null,
        skus: SKU[],
        dateCreated: Date,
        dateUpdated: Date
    ) {
        super(id, displayName, description, skus, dateCreated, dateUpdated)
    }

    hasSKUsOnSale(): boolean {
        return this.skus.some(sku => sku.onSale)
    }
}
